import { getJSON, putJSON } from 'bv-fetch';

export const fetchSalaryRanges = () => getJSON('/api/occupation_and_salary/salary_ranges');
export const searchOccupations = (query, page) => (
  getJSON('/api/occupation_and_salary/occupations', {
    filter: query,
    page,
    size: 30,
    sort: 'description,asc',
  })
);

export const updateOccupationAndSalary = (params) => putJSON('/api/occupation_and_salary', params);
export const getOccupationAndSalary = () => getJSON('/api/occupation_and_salary');
