import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';
import { getEmployerDetailsSettings } from '../api';
import EmployerDetailsForm from './employer_details_form';

const EmployerDetailsContainer = ({ setEmployerDetailsVisible, disabled }) => {
  const [employerDetailsSettings, isLoading] = useFetch(getEmployerDetailsSettings);

  useEffect(() => {
    if (!isLoading && employerDetailsSettings?.formVisible) {
      setEmployerDetailsVisible(true);
    }
  }, [isLoading]);

  if (isLoading || !employerDetailsSettings?.formVisible) return null;

  return (
    <EmployerDetailsForm
      fieldsRequired={!!employerDetailsSettings?.fieldsMandatory}
      employerData={employerDetailsSettings?.employerData}
      disabled={disabled}
    />
  );
};

EmployerDetailsContainer.propTypes = {
  setEmployerDetailsVisible: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default EmployerDetailsContainer;
