import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Select } from 'bv-components';

const Salary = (props) => {
  const { input: { onChange, value }, options, disabled } = props;

  return (
    <div className="bvs-form-group">
      <Select
        includeBlank={t('occupation_and_salary.form.salary.placeholder')}
        name="salary"
        options={options}
        onChange={(e) => onChange(Number(e.target.value))}
        value={`${value}`}
        disabled={disabled}
      />
    </div>
  );
};

Salary.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Salary;
