import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';
import { Spinner, RenderInBody } from 'bv-components';
import { format } from 'bv-helpers/number';

import { postEmployerDetails } from 'EmployerDetails/api';
import {
  fetchSalaryRanges,
  updateOccupationAndSalary,
  getOccupationAndSalary,
  searchOccupations,
} from './api';

import OccupationAndSalaryForm from './components/occupation_and_salary_form';
import SuccessSubmitModal from './components/success_submit_modal';

const composeFormInitialValues = (values) => {
  if (values.id) {
    return {
      selectedOccupation: values.occupation,
      salary: values.salary.id,
      occupationLabel: values.occupation.label,
    };
  }

  return {
    selectedOccupation: {},
  };
};

/* eslint react/destructuring-assignment:off */
const OccupationAndSalaryContainer = (props) => {
  const [formValues, setFormValues] = useState(props.formValues);
  const [salaryRanges, salaryRangesAreLoading] = useFetch(fetchSalaryRanges);
  const [showSuccessSubmitModal, setShowSuccessSubmitModal] = useState(false);
  const [preloadedOccupations, setPreloadedOccupations] = useState([]);

  useEffect(() => {
    if (!formValues) {
      getOccupationAndSalary().then((data) => {
        setFormValues(data);
      });
    }
  }, []);

  useEffect(() => {
    if (formValues && formValues.editable) {
      const preloadOccupations = async () => {
        const query = '';
        const page = 0;
        const { data } = await searchOccupations(query, page);

        setPreloadedOccupations(data);
      };

      preloadOccupations();
    }
  }, [formValues]);

  const updateEmployerDetails = (values) => {
    if (!values.name || !values.phone || !values.address) {
      return Promise.resolve({ success: true });
    }

    return postEmployerDetails({
      employer_name: values.name,
      employer_phone: values.phone,
      employer_address: values.address,
      employer_pip: values.pip || false,
    });
  };

  const submit = async (values) => {
    const updateResult = await updateEmployerDetails(values);

    if (updateResult.success) {
      const updateOSResult = await updateOccupationAndSalary({
        occupation_id: values.selectedOccupation.id,
        salary_id: values.salary,
      });
      if (updateOSResult.success) {
        setShowSuccessSubmitModal(true);
      }
    }
  };

  const shouldRenderSpinner = (
    !formValues
    || salaryRangesAreLoading
    || (formValues && formValues.editable && !preloadedOccupations.length)
  );

  if (shouldRenderSpinner) return <Spinner />;

  return (
    <>
      <OccupationAndSalaryForm
        salaryRanges={salaryRanges.map(({ value, label }) => ({
          value,
          label: format(label),
        }))}
        onSubmit={submit}
        initialValues={composeFormInitialValues(formValues)}
        editable={formValues.editable}
        preloadedOccupations={preloadedOccupations}
        renderInModal={props.renderInModal}
        employerDetails={props.employerDetails}
        contactUsVisible={props.contactUsVisible}
      />
      {showSuccessSubmitModal ? (
        <RenderInBody>
          <SuccessSubmitModal onClose={props.onSuccessModalClose} />
        </RenderInBody>
      ) : null}
    </>
  );
};

OccupationAndSalaryContainer.propTypes = {
  formValues: PropTypes.shape({
    id: PropTypes.number,
    required: PropTypes.bool,
    occupation: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    salary: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  }),
  onSuccessModalClose: PropTypes.func.isRequired,
  renderInModal: PropTypes.bool,
  employerDetails: PropTypes.bool,
  contactUsVisible: PropTypes.bool,
};

OccupationAndSalaryContainer.defaultProps = {
  formValues: undefined,
  renderInModal: false,
  employerDetails: false,
  contactUsVisible: true,
};

export default OccupationAndSalaryContainer;
