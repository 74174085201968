import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';

const SuccessSubmitModal = ({ onClose }) => (
  <Modal
    success
    actions={[{
      id: 'ok-btn',
      label: t('occupation_and_salary.form.submit.success.close'),
      primary: true,
      close: true,
      onClick: onClose,
    }]}
  >
    <p className="bvs-text-success">
      {t('occupation_and_salary.form.submit.success.message.title')}
    </p>
    <p className="bvs-text-success">
      {t('occupation_and_salary.form.submit.success.message.body')}
    </p>
  </Modal>
);

SuccessSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuccessSubmitModal;
