import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Field } from 'react-final-form';
import { sanitize } from 'dompurify';
import classnames from 'classnames';
import {
  composeValidators, minLength, maxLength, phoneSymbols,
} from '../validators';

const settings = {
  name: {
    minLength: 2,
    maxLength: 50,
  },
  phone: {
    minLength: 2,
    maxLength: 50,
  },
  address: {
    minLength: 2,
    maxLength: 50,
  },
};

const EmployerDetailsForm = ({ fieldsRequired, employerData, disabled }) => {
  const tKeyPrefix = 'employer_details';
  const tKeyFormPrefix = `${tKeyPrefix}.form`;

  const formFields = [
    { name: 'name' },
    {
      name: 'phone',
      validators: [phoneSymbols(fieldsRequired, t(`${tKeyFormPrefix}.phone_validation_error`))],
    },
    { name: 'address' },
  ].map(({ name, validators = [] }) => ({
    name,
    validation: composeValidators(
      ...validators,
      minLength(
        settings[name].minLength,
        fieldsRequired,
        t(`${tKeyFormPrefix}.min_length_validation_error`, { limit: settings[name].minLength }),
      ),
      maxLength(
        settings[name].maxLength,
        fieldsRequired,
        t(`${tKeyFormPrefix}.max_length_validation_error`, { limit: settings[name].maxLength }),
      )),
    placeholder: t(`${tKeyFormPrefix}.${name}_placeholder`),
  }));

  return (
    <>
      <h1 className="bvs-header text-center">
        {t(`${tKeyPrefix}.title`)}
      </h1>
      {formFields.map((field) => (
        <Field
          key={`key${field.name}`}
          name={field.name}
          validate={field.validation}
          initialValue={employerData[field.name]}
        >
          {({ input, meta }) => (
            <div className="bvs-form-group">
              <input
                {...input}
                type="text"
                id={input.name}
                className={classnames('bvs-form-control', {
                  error: meta.error && meta.visited,
                  'with-value': input.value && input.value !== '',
                })}
                disabled={disabled}
              />
              <label htmlFor={input.name} className="bvs-form-control-placeholder">
                {field.placeholder}
              </label>
              {meta.error && meta.visited && (
                <span className="bvs-form-error-msg">
                  {meta.error}
                </span>
              )}
            </div>
          )}
        </Field>
      ))}
      <Field
        name="pip"
      >
        {({ input }) => (
          <div className="occupation-and-salary-checkbox">
            <label
              htmlFor="pip"
            >
              <input
                {...input}
                name="pip"
                type="checkbox"
                id="pip"
                disabled={disabled}
                checked={employerData.pip}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    t(`${tKeyFormPrefix}.pep_hio_title`,
                      { link: 'https://www.fintrac-canafe.gc.ca/individuals-individus/faq-pep-eng' },
                    ),
                  ),
                }}
              />
            </label>
          </div>
        )}
      </Field>
    </>
  );
};

EmployerDetailsForm.propTypes = {
  fieldsRequired: PropTypes.bool.isRequired,
  employerData: PropTypes.shape({
    phone: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    pip: PropTypes.bool,
  }),
  disabled: PropTypes.bool.isRequired,
};

EmployerDetailsForm.defaultProps = {
  employerData: {
    phone: '',
    name: '',
    address: '',
    pip: false,
  },
};

export default EmployerDetailsForm;
