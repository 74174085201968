export const minLength = (min, isRequired, errorMessage) => (value) => {
  if (!value) {
    return isRequired ? true : undefined;
  }

  return (value.length >= min) ? undefined : errorMessage;
};

export const maxLength = (max, isRequired, errorMessage) => (value) => {
  if (!value) {
    return isRequired ? true : undefined;
  }

  return (value.length <= max) ? undefined : errorMessage;
};

export const phoneSymbols = (isRequired, errorMessage) => (value) => {
  if (!value) {
    return isRequired ? true : undefined;
  }

  return /[a-zA-Z]/.test(value) ? errorMessage : undefined;
};

export const composeValidators = (...validators) => (value) => validators.reduce(
  (error, validator) => error || validator(value), undefined,
);
