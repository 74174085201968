import {
  getJSON, postJSON,
} from 'bv-fetch';

const employerDetailsApiURL = '/api/employer_details';

export const getEmployerDetailsSettings = () => (
  getJSON(employerDetailsApiURL)
);

export const postEmployerDetails = (params) => (
  postJSON(employerDetailsApiURL, params)
);
