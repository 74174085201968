import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { searchOccupations } from '../../api';

const Occupation = (props) => {
  const {
    input,
    onSelectCallback,
    disabled,
    meta: {
      error, touched, active,
    },
    preloadedOccupations,
  } = props;

  const [inputValue, setInputValue] = useState(input.value || '');
  const [occupations, setOccupations] = useState(preloadedOccupations);
  const [showNotFoundError, setShowNotFoundError] = useState(false);
  const [searchPageNumber, setSearchPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const inputRef = useRef(null);

  const triggerSearch = (query, { page, appendResults }) => (
    searchOccupations(query, page).then(({ lastPage, data }) => {
      setIsLastPage(lastPage);
      setShowNotFoundError(!data.length);

      if (appendResults) {
        setSearchPageNumber(searchPageNumber + 1);
        setOccupations(occupations.concat(data));
      } else {
        setSearchPageNumber(0);
        setOccupations(data);
      }
    })
  );

  const arrangeSuggestions = ({ reason, value: val }) => {
    if (reason === 'input-changed' && (val.trim().length || !val.length)) {
      if (showNotFoundError) {
        setShowNotFoundError(false);
      }

      setTimeout(() => {
        if (val === inputRef.current.value) {
          triggerSearch(val.trim(), { appendResults: false, page: 0 });
        }
      }, 300);
    }
  };

  const handleMenuScroll = (query) => (e) => {
    const target = e.currentTarget;

    const scrollDiff = target.scrollHeight - Math.ceil(target.scrollTop);
    const scrollPrecission = 1;
    const scrollIsOver = (target.clientHeight - scrollPrecission) <= scrollDiff
      && scrollDiff <= (target.clientHeight + scrollPrecission);

    if (scrollIsOver && !isLastPage) {
      triggerSearch(query, { appendResults: true, page: searchPageNumber + 1 });
    }
  };

  const renderInputComponent = (inputProps) => {
    let errorKey;

    if (showNotFoundError) {
      errorKey = 'occupation_and_salary.form.occupation.errors.not_found';
    } else if (touched && !active && error) {
      errorKey = error;
    }

    return (
      <div className="bvs-form-group">
        <input
          {...inputProps}
          className={classnames('bvs-form-control bvs-link', {
            error: errorKey,
          })}
        />
        {errorKey && (
          <span className="bvs-form-error-msg">
            {t(errorKey)}
          </span>
        )}
      </div>
    );
  };

  return (
    <Autosuggest
      theme={{
        container: 'occupation-and-salary-autosuggest',
        suggestionsContainer: 'occupation-and-salary__menu',
        suggestionsContainerOpen: 'occupation-and-salary__menu--open',
        suggestion: 'occupation-and-salary__menu-item',
        suggestionHighlighted: 'occupation-and-salary__menu-item--highlighted',
      }}
      getSuggestionValue={(item) => item.label}
      suggestions={occupations}
      onSuggestionsClearRequested={() => {}}
      onSuggestionsFetchRequested={arrangeSuggestions}
      onSuggestionSelected={(_, { suggestion, suggestionValue }) => {
        input.onChange(suggestionValue);
        onSelectCallback(suggestion);
      }}
      renderSuggestion={(item) => <div>{item.label}</div>}
      renderInputComponent={renderInputComponent}
      inputProps={{
        ...input,
        ref: inputRef,
        type: 'text',
        name: 'occupation',
        placeholder: t('occupation_and_salary.form.occupation.placeholder'),
        disabled,
        value: inputValue,
        onChange: (e, { newValue }) => {
          setInputValue(newValue);
          input.onChange(newValue);
        },
      }}
      renderSuggestionsContainer={({ containerProps, children, query }) => (
        <div
          {...containerProps}
          onScroll={handleMenuScroll(query)}
        >
          {children}
        </div>
      )}
      shouldRenderSuggestions={() => true}
    />
  );
};

Occupation.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onSelectCallback: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    active: PropTypes.bool,
  }).isRequired,
  preloadedOccupations: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.array,
  ]).isRequired,
};

export default Occupation;
