import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { Icon } from 'bv-components';

const AdditionalInfoContent = () => (
  <>
    {t('occupation_and_salary.additional_info.body')
      .split('\n')
      .map((line, index) => (
        <p key={line} id={index} className="bvs-p">{line}</p>
      ))}
  </>
);

const AdditionalInfo = ({ expanded }) => {
  const [textExpanded, toggleText] = useState(expanded);

  const headerClassName = classnames('sg-portal-section__header', {
    active: textExpanded,
  });

  return (
    <div className="sg-portal-section__wrapper">
      <div className="sg-portal-section__body">
        <div className={headerClassName} onClick={() => toggleText(!textExpanded)}>
          <h1 className="bvs-header">{t('occupation_and_salary.additional_info.title')}</h1>
          <Icon
            className="bvs-link"
            id={textExpanded ? 'arrow-solid-circle-up' : 'arrow-solid-circle-down'}
          />
        </div>
        <div className={textExpanded ? '' : 'is-short'}>
          {textExpanded && <AdditionalInfoContent />}
        </div>
      </div>
    </div>
  );
};

AdditionalInfo.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default AdditionalInfo;
