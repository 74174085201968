import { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Form, Field } from 'react-final-form';
import {
  Button, Card, RenderInModal, Spinner,
} from 'bv-components';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import { getValidationFunction } from 'validation-helpers';
import classnames from 'classnames';
import EmployerDetails from 'EmployerDetails';
import Occupation from './fields/occupation';
import Salary from './fields/salary';
import AdditionalInfo from './additional_info';

const salaryValidations = getValidationFunction([
  {
    type: 'mandatory',
    errorMessage: 'occupation_and_salary.form.salary.errors.required',
  },
]);

const occupationValidations = getValidationFunction([
  {
    type: 'mandatory',
    errorMessage: 'occupation_and_salary.form.occupation.errors.required',
  },
  {
    type: 'isEqualTo',
    value: (allValues) => allValues.selectedOccupation.label,
    errorMessage: 'occupation_and_salary.form.occupation.errors.choose_from_dropdown',
  },
]);

const OccupationAndSalaryForm = (props) => {
  const {
    onSubmit,
    salaryRanges,
    editable,
    initialValues,
    preloadedOccupations,
    renderInModal,
    contactUsVisible,
  } = props;

  const [isEmployerDetailsVisible, setEmployerDetailsVisible] = useState(false);

  const renderConfirmButton = (handleSubmit, invalid, submitting) => {
    const btnWrapperClasses = classnames('modal_sticky_button_wrapper bvs-button-sticky-wrapper', {
      disabled: invalid || !editable || submitting,
    });

    return (
      <div className={btnWrapperClasses}>
        <Button
          default={renderInModal}
          type="submit"
          primary
          label={t('occupation_and_salary.form.confirm')}
          disabled={invalid || !editable}
          className="bvs-button-sticky occupation-and-salary__submit"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit, submitting, invalid, form,
      }) => {
        if (submitting) return <Spinner />;

        return (
          <form className="occupation-and-salary">
            <div className="occupation-and-salary-content">
              <Card>
                <Field
                  name="occupationLabel"
                  component={Occupation}
                  onSelectCallback={(selectedOccupation) => {
                    form.change('selectedOccupation', selectedOccupation);
                  }}
                  validate={occupationValidations}
                  disabled={!editable}
                  preloadedOccupations={preloadedOccupations}
                />
                <Field
                  name="salary"
                  component={Salary}
                  options={salaryRanges}
                  validate={salaryValidations}
                  disabled={!editable}
                />
                <EmployerDetails
                  setEmployerDetailsVisible={setEmployerDetailsVisible}
                  disabled={!editable}
                />
              </Card>
              <AdditionalInfo expanded={!isEmployerDetailsVisible} />
              {contactUsVisible && <ContactUsFooter />}
            </div>
            {renderInModal ? (
              <RenderInModal>
                {renderConfirmButton(handleSubmit, invalid, submitting)}
              </RenderInModal>
            ) : renderConfirmButton(handleSubmit, invalid, submitting)}
          </form>
        );
      }}
    </Form>
  );
};

OccupationAndSalaryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  salaryRanges: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }).isRequired,
  ).isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  editable: PropTypes.bool.isRequired,
  preloadedOccupations: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    PropTypes.array,
  ]).isRequired,
  renderInModal: PropTypes.bool.isRequired,
  contactUsVisible: PropTypes.bool.isRequired,
};

export default OccupationAndSalaryForm;
